import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

document.addEventListener("DOMContentLoaded", () => {
    onAuthStateChanged(auth, (user) => {
        if (!user) {
            window.location.href = "/login";
        }
    });

    const reportsLink = document.getElementById("reportsLink") as HTMLAnchorElement;
    const levelsLink = document.getElementById("levelsLink") as HTMLAnchorElement;

    reportsLink?.addEventListener("click", () => {
        window.location.href = "/reports";
    });

    levelsLink?.addEventListener("click", () => {
        window.location.href = "/levels";
    });
});